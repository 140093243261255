import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet } from "react-helmet";
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';

import { } from './style.css'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Mostafa Milani Amin</title>
      <link rel="canonical" href="https://mostafamilani.ir/" />
    </Helmet>
    <App />
  </React.StrictMode>
);