import React from 'react';
import { Card, ListGroup } from 'react-bootstrap'
import './articolo.css'

class Articolo extends React.Component {
    render() {
        return (
            <Card className="articolo">
                <Card.Img variant="top" src={this.props.islamico.image} />
                <Card.Body>
                    <Card.Title>{this.props.islamico.titolo}</Card.Title>
                    <Card.Text>
                        {this.props.islamico.testo}
                    </Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush">
                    <ListGroup.Item><span style={{fontWeight: "bold"}}>Autore:</span> {this.props.islamico.autore}</ListGroup.Item>
                    <ListGroup.Item><span style={{fontWeight: "bold"}}>Fonte:</span> {this.props.islamico.fonte}</ListGroup.Item>
                    <ListGroup.Item><span style={{fontWeight: "bold"}}>Categoria:</span> {this.props.islamico.categoria}</ListGroup.Item>
                </ListGroup>
                <Card.Body>
                    <Card.Link href="articoli">Articoli</Card.Link>
                    <Card.Link href="articoli-islamici">Articoli Islamici</Card.Link>
                </Card.Body>
            </Card>
        )
    }
}

export default Articolo;