import React from 'react';
import { Container } from 'react-bootstrap'
class Infobar extends React.Component {
    // constructor() {
    //   super()
    //   }
    // }
    render() {
      return (
        <Container fluid>
          <nav className="navbar" id="infobar">
            <div className="container-fluid">
              <h5>Totale ordinati: {this.props.onTotal(this.props)} |
                Spesa Totale: {this.props.onTotalPrice(this.props)} € |
                Spesa media: {(this.props.onTotalPrice(this.props) / (this.props.onTotal(this.props) + 0.0000000001)).toFixed(3)} €
              </h5>
              <h5 id="orderbar" style={{ color: "#7fffd4" }}> </h5>
              <button className="badge text-bg" id="badge" onClick={() => window.location.reload(false)}>Reload!</button>
              <button className="badge text-bg" id="badge" onClick={() => this.props.onTotalReset(this.props)}>Azzera!</button>
              <button className="badge text-bg" id="badge" onClick={() => this.props.onTotalMax(this.props)}>Massimo!</button>
            </div>
          </nav>
        </Container>
      )
    }
  }

  export default Infobar;