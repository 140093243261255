import styles from './styles.module.css'
import { Navigate } from 'react-router-dom';
import React from 'react'
import { Socket } from 'socket.io-client';

class HomeChat extends React.Component {
// constructor(props) {
//         super(props);
// }

state = {
username: "",
room: "",
submitted: false
}


handleChangeUsername = event => {
  this.setState({ username: event.target.value });
}
handleChangeRoom = event => {
  this.setState({ room: event.target.value });
}

// handleClick = event => {
//   this.setState({ submitted: true }) && 
//             <Navigate to={"chat"} true state={this.state.submitted}
//           />
// }

// joinRoom = () => {
//   if (this.props.room !== '' && this.props.username !== '') {
//     Socket.emit('join_room', { username: this.props.username, room: this.props.room });
//   }
// };

// this.useNavigate('chat', { replace: true });

// handleClick = () => {
//   this.setState({ submitted: true })
//   if (this.props.room !== '' && this.props.username !== '' && this.state.submitted) {
//     // Socket.emit('join_room', { username: this.props.username, room: this.props.room });
//     <Navigate to={"chat"} true
//     state={this.state.submitted}
//     />
// } 
// }

handleClick = async () => {
  // this.setState({submitted: true});
  // this.state.submitted && 
  // <Navigate to={"chat"} replace={true}
  await alert('ok')
// />
} 



render() {

//   this.state.submitted && 
//   <Navigate to={"chat"} true state={this.state.submitted}
// />

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <h1 style={{color: "#0d6efd"}}>{`Milani Chat 💬`}</h1>
        <input
          className={styles.input}
          placeholder='Username...'
          onChange={(e) => this.handleChangeUsername}
        />
        <select
          className={styles.input}
          onChange={(e) => this.handleChangeRoom}
        >
          <option>-- Select Room --</option>
          <option value='javascript'>JavaScript</option>
          <option value='node'>Node</option>
          <option value='express'>Express</option>
          <option value='react'>React</option>
        </select>

        <button
          className='btn btn-primary'
          style={{ width: '100%' }}
          onClick={() => this.handleClick}>
          Join Room
        </button>


        {/* {this.setState({ submitted: true }) && 
            <Navigate to={"chathome/chat"} true state={this.state.submitted}
          />} */}
        </div>
        </div>

)

}
}

export default HomeChat;