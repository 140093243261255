import React, { Component } from "react";
import { Form, Button, Container, Navbar, Nav, NavDropdown, Transition } from 'react-bootstrap';
import { CSSTransition } from "react-transition-group";
import Moment from 'react-moment';
import '../../style.css'

class MyNavbar extends Component {
  // constructor(props) {
  //   super(props)
  // }
  render() {
    return (
      <Navbar id="navbar" fixed="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/" id="navbarBrand">〽️ Mostafa Milani Amin</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Nav.Link id="navbarScrollingDropdown" href="/farsi">فارسی</Nav.Link>
              <NavDropdown Transition title="Principali" id="navbarScrollingDropdown" display="dynamic">
                <NavDropdown.Item style={{color: "gold"}} href="/principali/biografia">Biografia</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/principali/contattami">Contattami</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Islam" id="navbarScrollingDropdown" menuVariant="dark">
                <NavDropdown.Item style={{color: "gold"}} href="/islam/islam-per-tutti">Islam per Tutti</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/islam/fonti-islamiche">Fonti Islamiche</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Articoli" id="navbarScrollingDropdown" menuVariant="dark">
                <NavDropdown.Item style={{color: "gold"}} href="/articoli/articoli-islamici">Islamici</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/articoli/articoli-scentifici">Scientifici</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/articoli/articoli-informatici">Informatici</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/articoli/articoli-politici">Politici</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/articoli/altri-articoli">Altri articoli</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item style={{color: "gold"}} href="indice-articoli">Indice degli articoli</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Functions" id="navbarScrollingDropdown"  menuVariant="dark">
                <NavDropdown.Item style={{color: "gold"}} href="/functions/most-repeated-letter">Most Repeated Letter</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/functions/caesar-cipher">Caesar Cipher</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/functions/spell-integer">Spell Integer</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/functions/remove-emoji">Remove Emoji</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/functions/remove-accent">Remove Accent</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/functions/celsius-fahrenheit-kelvin">Celsius Fahrenheit Kelvin</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/functions/test-palindrome">Test Palindrome</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/functions/randopass">Rando Pass</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/functions/abjad">Abjad</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/functions/fibonacci">Fibonacci</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/functions/arabic-to-roman">Arabic to Roman</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item style={{color: "gold"}} href="/functions/all-functions">All Functions</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item style={{color: "gold"}} href="/functions/http-req">Richieste HTTP con Axios e React.js</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Demo" id="navbarScrollingDropdown" menuVariant="dark">
                <NavDropdown.Item style={{color: "gold"}} href="/demo/sushi">Sushi</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="/demo/cucina">Cucina</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Links" id="navbarScrollingDropdown" menuVariant="dark">
                <NavDropdown.Item style={{color: "gold"}} href="https://react-bootstrap.github.io/components/alerts" target="_blank">React Bootstrap</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="https://it.islamic-sources.com/" target="_blank">Islamic Sources</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="https://www.freecodecamp.org/news/build-a-realtime-chat-app-with-react-express-socketio-and-harperdb/" target="_blank">Real-time Chat App</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="https://it.reactjs.org/" target="_blank">React</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="https://d.easytrader.emofid.com/" target="_blank">Easy Trader</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="https://blog.karenying.com/posts/50-shades-of-dark-mode-gray" target="_blank">50 Shades of Dark Mode Gray</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="https://www.tgju.org/" target="_blank">طلا و سکه و ارز</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="https://recipes-blog-kauanidev.vercel.app/" target="_blank">Ricette</NavDropdown.Item>
                <NavDropdown.Item style={{color: "gold"}} href="https://mostafamilani.ir/" target="_blank">mostafamilani.ir</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item style={{color: "gold"}} href="https://github.com/Mostafa-Milani-Amin" target="_blank">MyGithub</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
              />
              <Button variant="btn btn-outline-warning">Search</Button>
            </Form>
            <Moment id="moment" format="HH:mm:ss (DD/MM/YYYY)" interval={1}/>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }
}

export default MyNavbar;