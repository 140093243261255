import React from 'react';
import { Container } from 'react-bootstrap';

export default class HttpReq extends React.Component {
    render() {
        return (
            <Container fluid>
                <h1>Effettuare richieste HTTP con Axios e React.js</h1>
                <p>Axios è una libreria Javascript che permette di connettersi con le API di backend e gestire le richieste effettuate tramite il protocollo HTTP.
                    Il vantaggio di Axios risiede nel suo essere promise-based, permettendo quindi l'implementazione di codice asincrono. Il codice asincrono permetterà, in una pagina, di caricare più elementi contemporaneamente invece che in maniera sequenziale, snellendo sensibilmente i tempi di caricamento.
                    Il Promise, su cui si basa Axios, è invece un oggetto di Javascript che permette di completare delle richieste in maniera asincrona, facendole passare da tre stati (in sospeso, soddisfatta, rifiutata).
                    In questa guida verranno illustrati alcuni esempi su come effettuare richieste HTTP GET e POST asincrone in un'applicazione React.js usando la libreria Axios.<br/>
                    Qui potete trovare tutti i codici delle funzioni usate: <a href="https://www.cloud.it/tutorial/effettuare-richieste-http-con-axios-e-reactjs.aspx#UsareunaistanzaBaseinAxios" target="_blank" rel="noreferrer">fonte</a>.</p>
            </Container>
        )
    }
}