import React from 'react';
import { Row } from 'react-bootstrap'
import Articolo from '../../../components/articolo/articolo';
import statearticoli from '../../../components/states/state-articoli'

class ArticoliIslamici extends React.Component {
    statearticoli = statearticoli
    render() {
        return(
            <Row xs={1} md={1} className="g-4">
                                    {this.statearticoli.islamici.map(islamico => (
                                        <Articolo
                                            key={islamico.id}
                                            // onDeleteBook={this.handleDeleteBook}
                                            islamico={islamico}
                                            />
                                    ))}
                                </Row>
        )
    }
}

export default ArticoliIslamici;