import emailjs from 'emailjs-com';
import { useRef } from 'react';
import { Container } from 'react-bootstrap'

export default function Contattami() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'milani141',
        'template_milani141',
        form.current,
        'YPPqLOhbtuU9PZtEM'
      )
      .then((result) => {
        console.log(result.text);
        alert(result.text);
        window.open("/", "_self")
      }, (error) => {
        alert(error.text);
        window.location.reload(false)
      });
  };

  return (
    <Container fluid>
          <div className="row">
            <div className="col align-self-center">
              <h1 className="text-center">📧 CONTATTAMI 📧</h1>
              <form ref={form} onSubmit={sendEmail}>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="name"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="enter your name"
                    style={{backgroundColor: "#a5d9fd"}}
                  />
                </div>
                <div className="form-group">
                  <br />
                  <label htmlFor="email">Email address</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="email"
                    placeholder="enter your email"
                    style={{backgroundColor: "#a5d9fd"}}
                  />
                </div>
                <div className="form-group">
                  <br />
                  <label htmlFor="subject">Subject</label>
                  <input
                    type="text"
                    name="subject"
                    className="form-control"
                    id="subject"
                    placeholder="enter email subject"
                    style={{backgroundColor: "#a5d9fd"}}
                  />
                </div>
                <br />
                <div className="form-group">
                  <label htmlFor="email_body">Message</label>
                  <textarea
                    className="form-control"
                    name="email_body"
                    id="email_body"
                    rows="5"
                    style={{backgroundColor: "#a5d9fd"}}
                  ></textarea>
                </div>
                <br />
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </div>
    </Container>
  )
}



