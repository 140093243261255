import React from 'react';
import { SplitButton, Anchor } from 'react-bootstrap';
import './split-button.css'

class MySplitButton extends React.Component {
  render() {
    return (
      <SplitButton
        key="end"
        // id="dropdown-button-drop-end"
        drop="end"
        variant="secondary"
        title=""
        id="mysplitbutton"
      >
        <Anchor id="myanchor" href="/">Home Page</Anchor><br />
        <Anchor id="myanchor" href="/principali/biografia">Biografia</Anchor><br />
        <Anchor id="myanchor" href="/principali/contattami">Contattami</Anchor><br />
        <Anchor id="myanchor" href="/islam/fonti-islamiche">Fonti Islamiche</Anchor><br />
        <Anchor id="myanchor" href="/islam/islam-per-tutti">Islam per tutti</Anchor><br />
        <Anchor id="myanchor" href="/articoli/articoli-islamici">Articoli Islamici</Anchor><br />
        <Anchor id="myanchor" href="/functions/all-functions">All functions</Anchor><br />
        <Anchor id="myanchor" href="/functions/abjad">Abjad</Anchor><br />
        <Anchor id="myanchor" href="/farsi">فارسی</Anchor><br />
      </SplitButton>
    )
  }
}

export default MySplitButton;