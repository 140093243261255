import { Component } from 'react'
import { Alert, Container, Col, Row } from 'react-bootstrap'
import copy from 'copy-to-clipboard'
import print from 'ink-html'

import mostrepeatedletter from 'most-repeated-letter'
import caesarcipher from 'caesarcipher2'
import spellinteger from 'spell-integer'
import removeemoji from 'remove-emoji'
import randopass from 'randopass'
import testpalindrome from 'test-palindrome'
import arabictoroman from 'arabictoroman'
import celsiusfahrenheitkelvin from 'celsiusfahrenheitkelvin'
import removeaccent from 'removeaccent'
import fibonacci from 'fibonacci-calc'
import abjad from 'abjad-calc'

import style from '../style.css'

export class MostRepeatedLetter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            output: ""
        }
    }
    render() {
        return (
            <Container fluid>
                <h3>Questa funzione trova la (prima) lettera più ripetuta in una stringa.</h3>
                <div className="input-group mb-3">
                    <button onClick={() => this.setState({ output: `La (prima) lettera più ripetuta della stringa "${this.inputmostrepeatedletter.value}" è "${mostrepeatedletter(this.inputmostrepeatedletter.value)}".` })} className="btn btn-success" type="button" id="button-addon1"><h5>🔤 Trova!</h5></button>
                    <input
                        ref={input => { this.inputmostrepeatedletter = input }}
                        className="form-control"
                        placeholder=""
                        aria-label=" . . ."
                        defaultValue=""
                    />
                    <button onClick={() => this.setState({ output: `` })} className="btn btn-danger" type="button" id="button-addon1"><h5>Reset! 🗑️</h5></button>
                </div>
                <Alert className="fnoutput" key="info" variant="info" show="false" style={{ color: "blue" }}>{this.state.output}</Alert>
                <button onClick={() => copy(`${mostrepeatedletter(this.inputmostrepeatedletter.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copy the result in the clipboard 📋</button>
                <button onClick={() => print(document.querySelector('.fnoutput'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button>
                <button onClick={() => window.location.reload(false)} className="btn btn-warning" type="button" id="button-addon1" style={{ align: "left" }}>Reload! 🔄</button>
            </Container>
        )
    }
}

export class CaesarCipher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            output: ""
        }
    }
    render() {
        return (
            <Container fluid>
                <h3>Questa funzione applica il Cifrario di Cesare, con chiave 0 ≤ n ≤ 26, a una stringa.</h3>
                <div className="input-group mb-3">
                    <button onClick={() => this.setState({ output: `La stringa "${this.inputcaesarcipherstr.value}" convertita col Cifrario di Cesare con chiave "${this.inputcaesarciphern.value}" diventa "${caesarcipher(this.inputcaesarcipherstr.value, this.inputcaesarciphern.value)}".` })} className="btn btn-success" type="button" id="button-addon1"><h5>👸🏽 Applica! </h5></button>
                    <input type="text"
                        ref={input => { this.inputcaesarcipherstr = input }}
                        className="form-control"
                        placeholder="stringa"
                        aria-label=" . . ."
                        defaultValue=""
                    />
                    <input type="text"
                        ref={input => { this.inputcaesarciphern = input }}
                        className="form-control"
                        placeholder="chiave"
                        aria-label=" . . ."
                        defaultValue=""
                    />
                    <button onClick={() => this.setState({ output: `` })} className="btn btn-danger" type="button" id="button-addon1"><h5>Reset! 🗑️</h5></button>
                </div>
                <Alert className="fnoutput" key="info" variant="info" show="false" style={{ color: "blue" }}>{this.state.output}</Alert>
                <button onClick={() => copy(`${caesarcipher(this.inputcaesarcipherstr.value, this.inputcaesarciphern.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copy the result in the clipboard 📋</button>
                <button onClick={() => print(document.querySelector('.fnoutput'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button>
                <button onClick={() => window.location.reload(false)} className="btn btn-warning" type="button" id="button-addon1" style={{ align: "left" }}>Reload! 🔄</button>
            </Container>
        )
    }
}

export class SpellInteger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            output: ""
        }
    }
    render() {
        return (
            <Container fluid>
                <h3>Questa funzione converte un numero intero positivo nel suo corrispondente letterale.</h3>
                <div className="input-group mb-3">
                    <button onClick={() => this.setState({ output: `Il corrispondente letterale della stringa "${this.inputspellinteger.value}" è "${spellinteger(this.inputspellinteger.value)}".` })
                    } className="btn btn-success" type="button" id="button-addon1"><h5>🔢 Converti!</h5></button>
                    <input type="text"
                        ref={input => { this.inputspellinteger = input }}
                        className="form-control"
                        placeholder=""
                        aria-label=" . . ."
                        defaultValue=""
                    />
                    <button onClick={() => this.setState({ output: `` })} className="btn btn-danger" type="button" id="button-addon1"><h5>Reset! 🗑️</h5></button>
                </div >
                <Alert className="fnoutput" key="info" variant="info" show="false" >{this.state.output}</Alert>
                <button onClick={() => copy(`${spellinteger(this.inputspellinteger.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copy the result in the clipboard 📋</button>
                <button onClick={() => print(document.querySelector('.fnoutput'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button>
                <button onClick={() => window.location.reload(false)} className="btn btn-warning" type="button" id="button-addon1" style={{ align: "left" }}>Reload! 🔄</button>
            </Container >
        )
    }
}

export class RemoveEmoji extends Component {
    constructor(props) {
        super(props);
        this.state = {
            output: ""
        }
    }
    render() {
        return (
            <Container fluid>
                <h3>Questa funzione rimuove gli emoji da una stringa.</h3>
                <div className="input-group mb-3">
                    <button onClick={() => this.setState({ output: `La stringa "${this.inputremoveemoji.value}" senza emoji è "${removeemoji(this.inputremoveemoji.value)}".` })} className="btn btn-success" type="button" id="button-addon1"><h5>🙂 Rimuovi!</h5></button>
                    <input type="text"
                        ref={input => { this.inputremoveemoji = input }}
                        className="form-control"
                        placeholder=""
                        aria-label=" . . ."
                        defaultValue=""
                    />
                    <button onClick={() => this.setState({ output: `` })} className="btn btn-danger" type="button" id="button-addon1"><h5>Reset! 🗑️</h5></button>
                </div>
                <Alert className="fnoutput" key="info" variant="info" show="false">{this.state.output}</Alert>
                <button onClick={() => copy(`${removeemoji(this.inputremoveemoji.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copy the result in the clipboard 📋</button>
                <button onClick={() => print(document.querySelector('.fnoutput'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button>
                <button onClick={() => window.location.reload(false)} className="btn btn-warning" type="button" id="button-addon1" style={{ align: "left" }}>Reload! 🔄</button>
            </Container>
        )
    }
}


export class RemoveAccent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            output: ""
        }
    }
    render() {
        return (
            <Container fluid>
                <h3>Questa funzione rimuove gli accenti dalle lettere di una stringa.</h3>
                <div className="input-group mb-3">
                    <button onClick={() => this.setState({ output: `La stringa "${this.inputremoveaccent.value}" senza accenti sulle lettere è "${removeaccent(this.inputremoveaccent.value)}".` })} className="btn btn-success" type="button" id="button-addon1"><h5>✂️ Rimuovi!</h5></button>
                    <input type="text"
                        ref={input => { this.inputremoveaccent = input }}
                        className="form-control"
                        placeholder=""
                        aria-label=" . . ."
                        defaultValue=""
                    />
                    <button onClick={() => this.setState({ output: `` })} className="btn btn-danger" type="button" id="button-addon1"><h5>Reset! 🗑️</h5></button>
                </div>
                <Alert className="fnoutput" key="info" variant="info" show="false">{this.state.output}</Alert>
                <button onClick={() => copy(`${removeaccent(this.inputremoveaccent.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copy the result in the clipboard 📋</button>
                <button onClick={() => print(document.querySelector('.fnoutput'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button>
                <button onClick={() => window.location.reload(false)} className="btn btn-warning" type="button" id="button-addon1" style={{ align: "left" }}>Reload! 🔄</button>
            </Container>
        )
    }
}


export class CelsiusFahrenheitKelvin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            output: ""
        }
    }
    render() {
        return (
            <Container fluid>
                <h3>Questa funzione converte la temperatura tra gradi celsius, fahrenheit e kelvin.</h3>
                <div className="input-group mb-3">
                    <button onClick={() => this.setState({ output: `"${this.inputcelsiusfahrenheitkelvin.value}" è pari a "${celsiusfahrenheitkelvin(this.inputcelsiusfahrenheitkelvin.value)}".` })} className="btn btn-success" type="button" id="button-addon1"><h5>🌡️ Converti!</h5></button>
                    <input type="text"
                        ref={input => { this.inputcelsiusfahrenheitkelvin = input }}
                        className="form-control"
                        placeholder="Inserire la temperatura da convertire facendola seguire da unità di misura (c, f o k)"
                        aria-label=" . . ."
                        defaultValue=""
                    />
                    <button onClick={() => this.setState({ output: `` })} className="btn btn-danger" type="button" id="button-addon1"><h5>Reset! 🗑️</h5></button>
                </div>
                <Alert className="fnoutput" key="info" variant="info" show="false">{this.state.output}</Alert>
                <button onClick={() => copy(`${celsiusfahrenheitkelvin(this.inputcelsiusfahrenheitkelvin.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copy the result in the clipboard 📋</button>
                <button onClick={() => print(document.querySelector(".fnoutput"))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button>
                <button onClick={() => window.location.reload(false)} className="btn btn-warning" type="button" id="button-addon1" style={{ align: "left" }}>Reload! 🔄</button>
            </Container>
        )
    }
}


export class TestPalindrome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            output: ""
        }
    }
    render() {
        return (
            <Container fluid>
                <h3>Questa funzione verifica se una stringa è un palindromo oppure no.</h3>
                <div className="input-group mb-3">
                    <button onClick={() => this.setState({ output: `La stringa "${this.inputtestpalindrome.value}" ${testpalindrome(this.inputtestpalindrome.value) ? "è un palindromo." : "non è un palindromo."}` })} className="btn btn-success" type="button" id="button-addon1"><h5> 🅿️ Palindromo? </h5></button>
                    <input type="text"
                        ref={input => { this.inputtestpalindrome = input }}
                        className="form-control"
                        placeholder=""
                        aria-label=" . . ."
                        defaultValue=""
                    />
                    <button onClick={() => this.setState({ output: `` })} className="btn btn-danger" type="button" id="button-addon1"><h5>Reset! 🗑️</h5></button>
                </div>
                <Alert className="fnoutput" key="info" variant="info" show="false">{this.state.output}</Alert>
                <button onClick={() => copy(`${testpalindrome(this.inputtestpalindrome.value) ? "È un palindromo." : "Non è un palindromo."}`)} className="btn btn-primary" type="button" id="button-addon1">Copy the result in the clipboard 📋</button>
                <button onClick={() => print(document.querySelector('.fnoutput'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button>
                <button onClick={() => window.location.reload(false)} className="btn btn-warning" type="button" id="button-addon1" style={{ align: "left" }}>Reload! 🔄</button>
            </Container>
        )
    }
}


export class RandoPass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            output: ""
        }
    }
    render() {
        return (
            <Container fluid>
                <h3>Questa funzione genera una random password di lunghezza 'n'.</h3>
                <div className="input-group mb-3">
                    <button onClick={() => this.setState({ output: `${randopass(this.inputrandopass.value)}` })} className="btn btn-success" type="button" id="button-addon1"><h5>🔑Genera!</h5></button>
                    <input type="text"
                        ref={input => { this.inputrandopass = input }}
                        className="form-control"
                        placeholder="Inserisci 'n'"
                        aria-label=" . . ."
                        defaultValue=""
                    />
                    <button onClick={() => this.setState({ output: `` })} className="btn btn-danger" type="button" id="button-addon1"><h5>Reset! 🗑️</h5></button>
                </div>
                <Alert className="fnoutput" show="false">La random password richiesta è: {this.state.output}</Alert>
                <button onClick={() => copy(`${this.state.output}`)} className="btn btn-primary" type="button" id="button-addon1">Copy the result in the clipboard 📋</button>
                <button onClick={() => print(document.querySelector('.fnoutput'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button>
                <button onClick={() => window.location.reload(false)} className="btn btn-warning" type="button" id="button-addon1" style={{ align: "left" }}>Reload! 🔄</button>
            </Container>
        )
    }
}


export class Abjad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            output: null
        }
    }
    render() {
        return (
            <Container fluid>
                <h3>Questa funzione calcola l'abjad di una stringa.</h3>
                <div className="input-group mb-3">
                    <button onClick={() => this.setState({ output: `L'abjad di "${this.inputabjad.value}" è uguale a ${abjad(this.inputabjad.value)}.` })} className="btn btn-success" type="button" id="button-addon1"><h5>🅰️ Calcola!</h5></button>
                    <input type="text"
                        ref={input => { this.inputabjad = input }}
                        className="form-control"
                        placeholder=""
                        aria-label=" . . ."
                        defaultValue=""
                    />
                    <button onClick={() => this.setState({ output: null })} className="btn btn-danger" type="button" id="button-addon1"><h5>Reset! 🗑️</h5></button>
                </div>
                <Alert className="fnoutput" key="info" variant="info" show="false">{this.state.output}</Alert>
                <button onClick={() => copy(`${abjad(this.inputabjad.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copy the result in the clipboard 📋</button>
                <button onClick={() => print(document.querySelector('.fnoutput'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button>
                <button onClick={() => window.location.reload(false)} className="btn btn-warning" type="button" id="button-addon1" style={{ align: "left" }}>Reload! 🔄</button>
            </Container>
        )
    }
}


export class Fibonacci extends Component {
    constructor() {
        super();
        this.state = {
            output: null
        }
    }
    render() {
        return (
            <Container fluid>
                <h3>Questa funzione calcola il fibonacci di un numero intero positivo.</h3>
                <div className="input-group mb-3">
                    <button onClick={() => this.setState({ output: `Il fibonacci di "${this.inputfibonacci.value}" è uguale a ${fibonacci(this.inputfibonacci.value)}.` })} className="btn btn-success" type="button" id="button-addon1"><h5>🌀 Calcola!</h5></button>
                    <input type="text"
                        ref={input => { this.inputfibonacci = input }}
                        className="form-control"
                        placeholder="Inserisci il numero"
                        aria-label=" . . ."
                        defaultValue=""
                    />
                    <button onClick={() => this.setState({ output: null })} className="btn btn-danger" type="button" id="button-addon1"><h5>Reset! 🗑️</h5></button>
                </div>
                <Alert className="fnoutput" key="info" variant="info" show="false">{this.state.output}</Alert>
                <button onClick={() => copy(`${fibonacci(this.inputfibonacci.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copy the result in the clipboard 📋</button>
                <button onClick={() => print(document.querySelector('.fnoutput'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button>
                <button onClick={() => window.location.reload(false)} className="btn btn-warning" type="button" id="button-addon1" style={{ align: "left" }}>Reload! 🔄</button>
            </Container>
        )
    }
}


export class ArabicToRoman extends Component {
    constructor(props) {
        super(props);
        this.state = {
            output: ""
        }
    }
    render() {
        return (
            <Container fluid>
                <h3>Questa funzione calcola l'equivalente romano di un numero.</h3>
                <div className="input-group mb-3">
                    <button onClick={() => this.setState({ output: `L'equivalente romano di "${this.inputarabictoroman.value}" è "${arabictoroman(this.inputarabictoroman.value)}".` })} className="btn btn-success" type="button" id="button-addon1"><h5>🏛️ Calcola!</h5></button>
                    <input type="text"
                        ref={input => { this.inputarabictoroman = input }}
                        className="form-control"
                        placeholder=""
                        aria-label=" . . ."
                        defaultValue=""
                    />
                    <button onClick={() => this.setState({ output: "" })} className="btn btn-danger" type="button" id="button-addon1"><h5>Reset! 🗑️</h5></button>
                </div>
                <Alert className="fnoutput" key="info" variant="info" show="false">{this.state.output}</Alert>
                <button onClick={() => copy(`${arabictoroman(this.inputarabictoroman.value)}`)} className="btn btn-primary" type="button" id="button-addon1">Copy the result in the clipboard 📋</button>
                <button onClick={() => print(document.querySelector('.fnoutput'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button>
                <button onClick={() => window.location.reload(false)} className="btn btn-warning" type="button" id="button-addon1" style={{ align: "left" }}>Reload! 🔄</button>
            </Container>
        )
    }
}

export class AllFunctions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            output1: null, output2: null, output3: null, output4: null, output5: null, output6: null, output7: null, output8: null, output9: null, output10: null, output11: null
        }
    }

    render() {

        return (


            <Container fluid>
                <Row style={{ paddingTop: "-65px" }}>
                    <Col style={{ width: "14%", position: "fixed" }}>
                        <h3 style={{ paddingTop: "0px", paddingBottom: "10px" }}><a href="#" style={{color: "gold", fontWeight: "900"}}>Funzioni</a></h3>
                        <p ><a href="#mostrepeatedletter" style={{color: "gold", fontWeight: "bold", textDecoration: "none"}}>Most Repeated</a></p>
                        <p><a href="#caesarcipher" style={{color: "gold", fontWeight: "bold", textDecoration: "none"}}>Caesar Cipher</a></p>
                        <p><a href="#spellinteger" style={{color: "gold", fontWeight: "bold", textDecoration: "none"}}>Spell Integer</a></p>
                        <p><a href="#removeemoji" style={{color: "gold", fontWeight: "bold", textDecoration: "none"}}>Remove Emoji</a></p>
                        <p><a href="#removeaccent" style={{color: "gold", fontWeight: "bold", textDecoration: "none"}}>Remove Accent</a></p>
                        <p><a href="#celsiusfahrenheitkelvin" style={{color: "gold", fontWeight: "bold", textDecoration: "none"}}>CelsFahrKelv</a></p>
                        <p><a href="#testpalindrome" style={{color: "gold", fontWeight: "bold", textDecoration: "none"}}>Test Palindrome</a></p>
                        <p><a href="#randopass" style={{color: "gold", fontWeight: "bold", textDecoration: "none"}}>Rando Pass</a></p>
                        <p><a href="#abjad" style={{color: "gold", fontWeight: "bold", textDecoration: "none"}}>Abjad</a></p>
                        <p><a href="#fibonacci" style={{color: "gold", fontWeight: "bold", textDecoration: "none"}}>Fibonacci</a></p>
                        <p><a href="#arabictoroman" style={{color: "gold", fontWeight: "bold", textDecoration: "none"}}>Arabic to Roman</a></p>
                    </Col>




                    <Col style={{ width: "90%", paddingLeft: "11rem" }}>

                        <h1 className="anchorbug" id="mostrepeatedletter" style={{marginTop: "-60px"}}>Most Repeated Letter</h1>
                        <div style={{ marginBottom: "-70px" }}>
                            <h5>Questa funzione trova la (prima) lettera più ripetuta in una stringa.</h5>
                            <div className="input-group mb-3">
                                <button onClick={() => this.setState({ output1: `La (prima) lettera più ripetuta nella stringa "${this.inputmostrepeatedletter.value}" è "${mostrepeatedletter(this.inputmostrepeatedletter.value)}".` })} className="btn btn-warning" type="button" id="button-addon1"><h5>🔤 Trova!</h5></button>
                                <input
                                    ref={input => { this.inputmostrepeatedletter = input }}
                                    className="form-control"
                                    placeholder=""
                                    aria-label=" . . ."
                                    defaultValue=""
                                />
                            </div>
                            <p style={{ height: "50px", color: "red", fontWeight: "bold", overflow: "auto", wordWrap: "break-word"}}>{this.state.output1}</p><hr/>
                        </div>


                        <h1 className="anchorbug" id="caesarcipher">Caesar Cipher</h1>
                        <div style={{ marginBottom: "-70px" }}>
                            <h5>Questa funzione applica il Cifrario di Cesare, con chiave 0 ≤ n ≤ 26, a una stringa.</h5>
                            <div className="input-group mb-3">
                                <button onClick={() => this.setState({ output2: `La stringa "${this.inputcaesarcipherstr.value}" convertita col Cifrario di Cesare con chiave "${this.inputcaesarciphern.value}" diventa "${caesarcipher(this.inputcaesarcipherstr.value, this.inputcaesarciphern.value)}".` })} className="btn btn-warning" type="button" id="button-addon1"><h5>👸🏽 Applica! </h5></button>
                                <input type="text"
                                    ref={input => { this.inputcaesarcipherstr = input }}
                                    className="form-control"
                                    placeholder="stringa"
                                    aria-label=" . . ."
                                    defaultValue=""
                                />
                                <input type="text"
                                    ref={input => { this.inputcaesarciphern = input }}
                                    className="form-control"
                                    placeholder="chiave"
                                    aria-label=" . . ."
                                    defaultValue=""
                                />
                            </div>
                            <p style={{ height: "50px", color: "red", fontWeight: "bold", overflow: "auto", wordWrap: "break-word"}}>{this.state.output2}</p><hr />
                        </div>


                        <h1 className="anchorbug" id="spellinteger">Spell Integer</h1>
                        <div style={{ marginBottom: "-70px" }}>
                            <h5>Questa funzione converte un numero intero positivo nel suo corrispondente letterale.</h5>
                            <div className="input-group mb-3">
                                <button onClick={() => this.setState({ output3: `Il corrispondente letterale della stringa "${this.inputspellinteger.value}" è "${spellinteger(this.inputspellinteger.value)}".` })} className="btn btn-warning" type="button" id="button-addon1"><h5>🔢 Converti!</h5></button>
                                <input type="text"
                                    ref={input => { this.inputspellinteger = input }}
                                    className="form-control"
                                    placeholder=""
                                    aria-label=" . . ."
                                    defaultValue=""
                                />
                            </div>
                            <p style={{ height: "50px", color: "red", fontWeight: "bold", overflow: "auto", wordWrap: "break-word"}}>{this.state.output3}</p><hr />
                        </div>

                        <h1 className="anchorbug" id="removeemoji">Remove Emoji</h1>
                        <div style={{ marginBottom: "-70px" }}>
                            <h5>Questa funzione rimuove gli emoji da una stringa.</h5>
                            <div className="input-group mb-3">
                                <button onClick={() => this.setState({ output4: `La stringa "${this.inputremoveemoji.value}" senza emoji è "${removeemoji(this.inputremoveemoji.value)}".` })} className="btn btn-warning" type="button" id="button-addon1"><h5>🙂 Rimuovi!</h5></button>
                                <input type="text"
                                    ref={input => { this.inputremoveemoji = input }}
                                    className="form-control"
                                    placeholder=""
                                    aria-label=" . . ."
                                    defaultValue=""
                                />
                            </div>
                            <p style={{ height: "50px", color: "red", fontWeight: "bold", overflow: "auto", wordWrap: "break-word"}}>{this.state.output4}</p><hr />
                        </div>

                        <h1 className="anchorbug" id="removeaccent">Remove Accent</h1>
                        <div style={{ marginBottom: "-70px" }}>
                            <h5>Questa funzione rimuove gli accenti dalle lettere di una stringa.</h5>
                            <div className="input-group mb-3">
                                <button onClick={() => this.setState({ output5: `La stringa "${this.inputremoveaccent.value}" senza accenti sulle lettere è "${removeaccent(this.inputremoveaccent.value)}".` })} className="btn btn-warning" type="button" id="button-addon1"><h5>✂️ Rimuovi!</h5></button>
                                <input type="text"
                                    ref={input => { this.inputremoveaccent = input }}
                                    className="form-control"
                                    placeholder=""
                                    aria-label=" . . ."
                                    defaultValue=""
                                />
                            </div>
                            <p style={{ height: "50px", color: "red", fontWeight: "bold", overflow: "auto", wordWrap: "break-word"}}>{this.state.output5}</p><hr />
                        </div>

                        <h1 className="anchorbug" id="celsiusfahrenheitkelvin">Celsius Fahrenheit Kelvin</h1>
                        <div style={{ marginBottom: "-70px" }}>
                            <h5>Questa funzione converte la temperatura tra gradi celsius, fahrenheit e kelvin.</h5>
                            <div className="input-group mb-3">
                                <button onClick={() => this.setState({ output6: `"${this.inputcelsiusfahrenheitkelvin.value}" è pari a "${celsiusfahrenheitkelvin(this.inputcelsiusfahrenheitkelvin.value)}".` })} className="btn btn-warning" type="button" id="button-addon1"><h5>🌡️ Converti!</h5></button>
                                <input type="text"
                                    ref={input => { this.inputcelsiusfahrenheitkelvin = input }}
                                    className="form-control"
                                    placeholder="Inserire la temperatura da convertire facendola seguire dall'unità di misura (c, f o k)"
                                    aria-label=" . . ."
                                    defaultValue=""
                                />
                            </div>
                            <p style={{ height: "50px", color: "red", fontWeight: "bold", overflow: "auto", wordWrap: "break-word"}}>{this.state.output6}</p><hr />
                        </div>

                        <h1 className="anchorbug" id="testpalindrome">Test Palindrome</h1>
                        <div style={{ marginBottom: "-70px" }}>
                            <h5>Questa funzione verifica se una stringa è un palindromo oppure no.</h5>
                            <div className="input-group mb-3">
                                <button onClick={() => {
                                    this.setState({ output7: `La stringa "${this.inputtestpalindrome.value}" ${testpalindrome(this.inputtestpalindrome.value) ? "è un palindromo." : "non è un palindromo."}` })
                                }
                                } className="btn btn-warning" type="button" id="button-addon1"><h5> 🅿️ Palindromo? </h5></button>
                                <input type="text"
                                    ref={input => { this.inputtestpalindrome = input }}
                                    className="form-control"
                                    placeholder=""
                                    aria-label=" . . ."
                                    defaultValue=""
                                />
                            </div>
                            <p style={{ height: "50px", color: "red", fontWeight: "bold", overflow: "auto", wordWrap: "break-word"}}>{this.state.output7}</p><hr />
                        </div>

                        <h1 className="anchorbug" id="randopass">Rando Pass</h1>
                        <div style={{ marginBottom: "-70px" }}>
                            <h5>Questa funzione genera una random password di lunghezza 'n'.</h5>
                            <div className="input-group mb-3">
                                <button onClick={() => this.setState({ output8: randopass(this.inputrandopass.value) })} className="btn btn-warning" type="button" id="button-addon1"><h5>🔑 Genera!</h5></button>
                                <input type="text"
                                    ref={input => { this.inputrandopass = input }}
                                    className="form-control"
                                    placeholder="Inserisci 'n'"
                                    aria-label=" . . ."
                                    defaultValue=""
                                />
                            </div>
                            <p style={{ height: "50px", color: "red", fontWeight: "bold", overflow: "auto", wordWrap: "break-word"}}>{this.state.output8}</p><hr />
                        </div>

                        <h1 className="anchorbug" id="abjad">Abjad</h1>
                        <div style={{ marginBottom: "-70px" }}>
                            <h5>Questa funzione calcola l'abjad di una stringa.</h5>
                            <div className="input-group mb-3">
                                <button onClick={() => this.setState({ output9: `L'abjad di "${this.inputabjad.value}" è uguale a ${abjad(this.inputabjad.value)}.` })} className="btn btn-warning" type="button" id="button-addon1"><h5>🅰️ Calcola!</h5></button>
                                <input type="text"
                                    ref={input => { this.inputabjad = input }}
                                    className="form-control"
                                    placeholder=""
                                    aria-label=" . . ."
                                    defaultValue=""
                                />
                            </div>
                            <p style={{ height: "50px", color: "red", fontWeight: "bold", overflow: "auto", wordWrap: "break-word"}}>{this.state.output9}</p><hr />
                        </div>


                        <h1 className="anchorbug" id="fibonacci">Fibonacci</h1>
                        <div style={{ marginBottom: "-70px" }}>
                            <h5>Questa funzione calcola il fibonacci di un numero intero positivo.</h5>
                            <div className="input-group mb-3">
                                <button onClick={() => this.setState({ output10: `Il fibonacci di "${this.inputfibonacci.value}" è uguale a ${fibonacci(this.inputfibonacci.value)}.` })} className="btn btn-warning" type="button" id="button-addon1"><h5>🌀 Calcola!</h5></button>
                                <input type="text"
                                    ref={input => { this.inputfibonacci = input }}
                                    className="form-control"
                                    placeholder="Inserisci il numero"
                                    aria-label=" . . ."
                                    defaultValue=""
                                />
                            </div>
                            <p style={{ height: "50px", color: "red", fontWeight: "bold", overflow: "auto", wordWrap: "break-word"}}>{this.state.output10}</p><hr />
                        </div>

                        <h1 className="anchorbug" id="arabictoroman">Arabic to Roman</h1>
                        <div style={{ marginBottom: "-70px" }}>
                            <h5>Questa funzione calcola l'equivalente romano di un numero intero positivo.</h5>
                            <div className="input-group mb-3">
                                <button onClick={() => this.setState({ output11: `L'equivalente romano di "${this.inputarabictoroman.value}" è "${arabictoroman(this.inputarabictoroman.value)}".` })} className="btn btn-warning" type="button" id="button-addon1"><h5>🏛️ Calcola!</h5></button>
                                <input type="text"
                                    ref={input => { this.inputarabictoroman = input }}
                                    className="form-control"
                                    placeholder=""
                                    aria-label=" . . ."
                                    defaultValue=""
                                />
                            </div>
                            <p style={{ height: "50px", color: "red", fontWeight: "bold", overflow: "auto" }}>{this.state.output11}</p><hr />
                        </div>

                    </Col>
                </Row>
            </Container>
        )
    }
}