import React from 'react';
import axios from 'axios';
import { Alert, Container } from 'react-bootstrap'
import copy from 'copy-to-clipboard'
import print from 'ink-html'

export default class GetReq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      output: null
    }
  }

  componentDidMount() {
    axios.get(`https://gist.githubusercontent.com/Mostafa-Milani-Amin/66834e9b36454a962c9b832b805d7485/raw/09cad312f6a3bb2cf56063a43225ef3aafd47158/jsonplaceholder.json`)
      .then(res => {
        const posts = res.data;
        this.setState({ posts });
      })
  }

  render() {
    return (
      <Container fluid>
        <h4>Implementare una richiesta GET</h4>
        <h6>All'interno del codice di questo programma, la prima parte di istruzioni serve a importare React e Axios per renderli utilizzabili nella componente.
          La parte in cui si esegue la richiesta è invece quella di componentDidMount, in cui, tramite il comando axios.get(url), esegui una richiesta di GET a una API per avere una promise che dovrebbe restituire un oggetto contenente i dati che assegnerai a post.</h6>
        <div className="input-group mb-3">
          <button onClick={() => this.setState({
            output: <ul>
              {this.state.posts.map(post => <li>{post[this.inputprop.value]}</li>)}
            </ul>
          })} className="btn btn-success" type="button" id="button-addon1"><h5>Mostra valori proprietà!</h5></button>
          <input type="text"
            ref={input => { this.inputprop = input }}
            className="form-control"
            placeholder="Inserisci una proprietà (id, title oppure value)."
            aria-label=" . . ."
            defaultValue=""
          />
          <button onClick={() => this.setState({ output: `` })} className="btn btn-danger" type="button" id="button-addon1"><h5>Reset! 🗑️</h5></button>
        </div>
        <Alert className="fnoutput" id="getreq" key="info" variant="info" show="false">{this.state.output}</Alert>

        <button onClick={() => copy(`${(this.state.posts.map((post => post[this.inputprop.value])).join("\n"))}`)} className="btn btn-primary" type="button" id="button-addon1">Copy the result in the clipboard 📋</button>
        <button onClick={() => print(document.querySelector('#getreq'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button>
        <button onClick={() => window.location.reload(false)} className="btn btn-warning" type="button" id="button-addon1" style={{ align: "left" }}>Reload! 🔄</button>
        <Container />
      </Container>
    )
  }
}
