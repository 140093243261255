import { Component } from "react"
import { Card, Container, Col } from 'react-bootstrap'

import state from "../components/states/state-cards";

export class Cucina extends Component {

  state = state

  render() {
    return (
      <Container fluid>
        <Col>
          <a title={this.props.recipe.descrizione} href={this.props.recipe.info} target="_blank" rel="noreferrer">
            <Card style={{ alignContent: "bottom", color: "gold", borderColor: "gold" }}>
              <Card.Img src={this.props.recipe.immagine} alt="..." target="_blank" rel="noreferrer" href={this.props.recipe.info} style={{ filter: "brightness(65%)" }} />
              <Card.ImgOverlay>
                <Card.Title style={{ textAlign: "center", textPosition: "bottom" }}>{this.props.recipe.titolo}</Card.Title>
                <Card.Text>
                </Card.Text>
              </Card.ImgOverlay>
            </Card >
          </a>
        </Col >
      </Container>
    )
  }
}