import React from 'react';
import { Alert, Container } from 'react-bootstrap'
import copy from 'copy-to-clipboard'
import print from 'ink-html'
import API from './api.js'

export default class ImportUrl extends React.Component {
  state = {
    id: "",
    output: ""
  }

  handleChange = event => {
    this.setState({ id: event.target.value });
  }
  
  handleSubmit = async event => {
    event.preventDefault();


    await API.delete(`posts/${this.state.id}`)
      .then(res => {
        // console.log(res);
        // console.log(res.data);
        this.setState({ output: JSON.stringify(res) })
      })
  }

  render() {
    return (
      <Container fluid>
        <h4>Usare una istanza Base in Axios</h4>
        <h6>Grazie a questa funzione, puoi definire ed importate un URL e diversi elementi di configurazione di Axios da richiamare nel codice in modo tale da risparmiare tempo nel riscriverlo per intero, qualora puntassi a una sezione differente dell'API.</h6>
        <form onSubmit={this.handleSubmit}>
          <div className="input-group mb-3">
            <button type="submit" className="btn btn-success" id="button-addon1"><h5>Mostra la risposta!</h5></button>
            <input type="text" name="title" onChange={this.handleChange} className="form-control" placeholder="Inserisci il numero dell'ID dell'oggetto di cui vuoi importare l'url." />
            <button onClick={() => this.setState({ output: `` })} className="btn btn-danger" type="button" id="button-addon1"><h5>Reset! 🗑️</h5></button>
          </div>
          <Alert className="fnoutput" id="importurl" key="info" variant="info" show="false">{this.state.output}</Alert>
          <button onClick={() => copy(`${this.state.output}`)} className="btn btn-primary" type="button" id="button-addon1">Copy the result in the clipboard 📋</button>
          <button onClick={() => print(document.querySelector('#importurl'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button>
          <button onClick={() => window.location.reload(false)} className="btn btn-warning" type="button" id="button-addon1" style={{ align: "left" }}>Reload! 🔄</button>
        </form>
      </Container>
    )
  }
}