import california from '../../multimedia/images/california.png';
import dragon from '../../multimedia/images/dragon.png';
import dynamite from '../../multimedia/images/dynamite.png';
import philadelphia from '../../multimedia/images/philadelphia.png';
import rainbow from '../../multimedia/images/rainbow.png';
import shrimp from '../../multimedia/images/shrimp.png';

const cardsState = {

    cards: [
        { id: 0, nome: "California", prezzo: 1.99, immagine: california, quantità: 0, quantitàmassima: 10 },
        { id: 1, nome: "Dragon", prezzo: 3.49, immagine: dragon, quantità: 0, quantitàmassima: 18 },
        { id: 2, nome: "Dynamite", prezzo: 2.49, immagine: dynamite, quantità: 0, quantitàmassima: 23 },
        { id: 3, nome: "Philadelphia", prezzo: 0.99, immagine: philadelphia, quantità: 0, quantitàmassima: 16 },
        { id: 4, nome: "Rainbow", prezzo: 2.99, immagine: rainbow, quantità: 0, quantitàmassima: 6 },
        { id: 5, nome: "Shrimp", prezzo: 1.49, immagine: shrimp, quantità: 0, quantitàmassima: 2 }
    ],

    books: [

        {
            id: 0,
            titolo: "La Retta Via",
            immagine: "https://it.islamic-sources.com/core/wp-content/uploads/barcodes/8233.png",
            descrizione: "Cinquecento tradizioni del sommo Profeta e della sua immacolata Famiglia.",
            dlpdf: "https://dl.islamic-sources.com/it/filebase/La%20Retta%20Via.pdf",
            dldocx: "https://dl.islamic-sources.com/it/filebase/La%20Retta%20Via.doc",
            info: "https://it.islamic-sources.com/Books/la-retta-via-500-tradizioni-del-profeta-muhammad-e-della-sua-immacolata-famiglia-2"
        },

        {
            id: 1,
            titolo: "Compendio della Dottrina Islamica",
            immagine: "https://it.islamic-sources.com/core/wp-content/uploads/barcodes/1145.png",
            descrizione: "Compendio dei princípi e dei precetti della religione islamica.",
            dlpdf: "https://dl.islamic-sources.com/it/filebase/Compendio%20della%20Dottrina%20Islamica.pdf",
            info: "https://it.islamic-sources.com/Books/compendio-della-dottrina-islamica-allamah-tabatabai"
        },

        {
            id: 2,
            titolo: "Alluhúf",
            immagine: "https://it.islamic-sources.com/core/wp-content/uploads/barcodes/1756.png",
            descrizione: "Narrazione della tragica vicenda di Karbalà e del martirio dell'Imam Hussain.",
            dlpdf: "https://dl.islamic-sources.com/it/filebase/Alluhuf.pdf",
            info: "https://it.islamic-sources.com/Books/alluhuf"
        },

        {
            id: 3,
            titolo: "La Parola di Alì",
            immagine: "https://it.islamic-sources.com/core/wp-content/uploads/barcodes/718.png",
            descrizione: "Sentenze brevi del Nahj al-Balagha, la raccolta più nota di sermoni, lettere e sentenze dell'Imam Alì.",
            dlpdf: "https://dl.islamic-sources.com/it/filebase/La%20Parola%20di%20Ali.pdf",
            info: "https://it.islamic-sources.com/Books/la-parola-di-ali-le-sentenze-brevi-del-nahju-l-balagah"
        },

        {
            id: 4,
            titolo: "L’Esempio Perfetto",
            immagine: "https://it.islamic-sources.com/core/wp-content/uploads/barcodes/1759.png",
            descrizione: "Elementi della biografia e della condotta di vita del nobile Profeta dell’Islam (S).",
            dlpdf: "https://dl.islamic-sources.com/it/filebase/L'Esempio%20Perfetto.pdf",
            info: "https://it.islamic-sources.com/Books/lesempio-perfetto-uno-sguardo-alla-condotta-morale-del-profeta-dellislam-s"
        },

        {
            id: 5,
            titolo: "La Preghiera",
            immagine: "https://it.islamic-sources.com/core/wp-content/uploads/barcodes/4978.png",
            descrizione: "L’uomo traviato può trovare pace in Dio e la preghiera è il miglior modo per rivolgersi a Lui.",
            dlpdf: "https://dl.islamic-sources.com/it/filebase/La%20preghiera.pdf",
            info: "https://it.islamic-sources.com/Books/la-preghiera"
        },

        {
            id: 6,
            titolo: "La Supplica del Pianto (Du’a Nudbah)",
            immagine: "https://it.islamic-sources.com/core/wp-content/uploads/barcodes/1818.png",
            descrizione: "È meritorio recitare questa invocazione nelle quattro feste: Fitr, Adha, Ghadir e Jumu’ah.",
            dlpdf: "https://dl.islamic-sources.com/it/filebase/La%20supplica%20del%20Pianto.pdf",
            info: "https://it.islamic-sources.com/Books/la-supplica-del-pianto-dua-nudbah"
        }

    ],

    recipes: [

        {
            id: 0,
            titolo: "Piccata di pollo",
            immagine: "https://www.giallozafferano.it/images/254-25403/Piccata-di-pollo_780x520_wm.jpg",
            descrizione: "Avete mai sentito parlare della 'piccata'? Quando l'abbiamo scoperta ci siamo incuriositi e abbiamo preparato la piccata di pollo!",
            info: "https://ricette.giallozafferano.it/Piccata-di-pollo.html"
        },

        {
            id: 1,
            titolo: "Spaghetti aglio, olio e peperoncino",
            immagine: "https://www.giallozafferano.it/images/251-25107/Spaghetti-aglio-olio-e-peperoncino-facili_780x520_wm.jpg?cb=82b1df5ccc4c47db76991c3ac12fbb13c25d54c0",
            descrizione: "Lo chef Luciano Monosilio ha preparato per noi degli spaghetti aglio, olio e peperoncino facili facendoci innamorare di una pasta semplice, intramontabile e dai sapori intensi!",
            info: "https://ricette.giallozafferano.it/Spaghetti-aglio-olio-e-peperoncino-facili.html"
        },

        {
            id: 2,
            titolo: "Delizie al limone",
            immagine: "https://www.giallozafferano.it/images/178-17863/Delizie-al-limone_780x520_wm.jpg?cb=82b1df5ccc4c47db76991c3ac12fbb13c25d54c0",
            descrizione: "L'inconfondibile profumo dei limoni di Amalfi e il gusto delicato della crema al limoncello sono i protagonisti di una ricetta che inebrierà le vostre papille gustative, oggi prepariamo le delizie al limone.",
            info: "https://ricette.giallozafferano.it/Delizie-al-limone.html"
        },

        {
            id: 3,
            titolo: "Melanzane ripiene",
            immagine: "https://www.giallozafferano.it/images/235-23593/Melanzane-ripiene_780x520_wm.jpg?cb=82b1df5ccc4c47db76991c3ac12fbb13c25d54c0",
            descrizione: "Le melanzane ripiene sono un piatto tipico della tradizione contadina spagnola che con il passare del tempo si è molto diffuso nella nostra penisola, dando vita a una interessante gamma di varianti.",
            info: "https://ricette.giallozafferano.it/Melanzane-ripiene.html"
        },

        {
            id: 4,
            titolo: "Mozzarella in carrozza",
            immagine: "https://www.giallozafferano.it/images/193-19389/Mozzarella-in-carrozza_450x300.jpg",
            descrizione: "Solo uno chef di origini partenopee poteva rivelarci tutti i segreti per realizzare la mozzarella in carrozza: una ricetta sfiziosa, diffusa anche nel Lazio con alcune differenze, molto semplice da preparare e che richiede piccoli accorgimenti per ottenere un risultato perfetto.",
            info: "https://ricette.giallozafferano.it/Mozzarella-in-carrozza.html"
        },

        {
            id: 5,
            titolo: "Mozzarella fritta",
            immagine: "https://www.giallozafferano.it/images/28-2823/Mozzarella-fritta_780x520_wm.jpg",
            descrizione: "La mozzarella fritta è un piatto sfizioso da offrire sia come aperitivo agli amici, magari insieme a un'originale focaccia arrotolata, che come secondo, accompagnato dalle verdure. E' un piatto semplice ma delizioso soprattutto se si utilizzano ingredienti freschi e di prima qualità.",
            info: "https://ricette.giallozafferano.it/Mozzarella-fritta.html"
        },

        {
            id: 6,
            titolo: "Puccia con burrata e fiori di zucca",
            immagine: "https://www.giallozafferano.it/images/211-21154/Puccia-con-burrata-e-fiori-di-zucca_780x520_wm.jpg",
            descrizione: "Ispirati da un pane della tradizione pugliese, vi proponiamo qui un piatto da gustare a morsi che esalta al meglio i sapori della stagione estiva: la puccia con burrata e fiori di zucca.",
            info: "https://ricette.giallozafferano.it/Puccia-con-burrata-e-fiori-di-zucca.html"
        },

        {
            id: 7,
            titolo: "Puccia salentina alle olive",
            immagine: "https://www.giallozafferano.it/images/157-15702/Puccia-salentina-alle-olive_780x520_wm.jpg",
            descrizione: "Le pucce salentine sono ancora il cibo tradizionalmente consumato il Giorno dell’Immacolata. In quella giornata è tradizione digiunare o al più mangiare velocemente una puccia per poi poter partecipare alle funzioni religiose.",
            info: "https://ricette.giallozafferano.it/Puccia-salentina-alle-olive.html"
        },

        {
            id: 8,
            titolo: "Pane con lievito istantaneo",
            immagine: "https://www.giallozafferano.it/images/208-20893/Panini-veloci-alle-olive_780x520_wm.jpg",
            descrizione: "Chi ha detto che per fare il pane sono necessarie lunghe ore di lievitazione? Il pane con lievito istantaneo si preparara in pochi minuti.",
            info: "https://ricette.giallozafferano.it/Panini-veloci-alle-olive.html"
        }

    ]

}

export default cardsState;