import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Row, ThemeProvider, Container, Tab, Tabs } from 'react-bootstrap'
import io from 'socket.io-client';

import MyNavbar from './components/main/navbar';
import Infobar from './components/main/infobar';
import MySplitButton from './components/main/split-button/split-button';
import Sidebar from './components/main/sidebar/sidebar';
import cardsState from './components/states/state-cards';
import HomeChat from './components/mychat/home'
import Chat from './components/mychat/chat'
import HttpReq from './components/httpreq/http-req';
import GetReq from './components/httpreq/tabs/get-req';
import PostReq from './components/httpreq/tabs/post-req';
import DeleteReq from './components/httpreq/tabs/delete-req';
import ImportUrl from './components/httpreq/tabs/import-url';
import Prova from './components/varie/prova/prova';
import Articoli from './components/articolo/articolo.js'


import { Home, Biografia, Sushi, PageNotFound, FontiIslamiche, IslamPerTutti, Farsi } from './pages/main';
import ArticoliIslamici from './pages/articoli/islamici/ArticoliIslamici'
import { MostRepeatedLetter, CaesarCipher, SpellInteger, RemoveEmoji, RemoveAccent, CelsiusFahrenheitKelvin, TestPalindrome, RandoPass, Abjad, Fibonacci, ArabicToRoman, AllFunctions } from './pages/functions';
import { Cucina } from './pages/cucina'
import Socials from './pages/socials'
import AppMail from './pages/contattami';

class App extends Component {
    // constructor(props) {
    // super(props);
    // }

    //  chatState = {
    //      username: "",
    //      room: "",
    //      setusername: "",
    //      setroom: "",
    //      socket: null
    //  }
     



    // socket = io.connect('http://localhost:4000');

    state = cardsState;



    handleDelete = cardId => {
        const cards = this.state.cards.filter(card => card.id !== cardId);
        alert(`Hai eliminato "${{ ...this.state.cards.filter(card => card.id === cardId)[0] }.nome} Roll"!`);
        setTimeout(() => this.setState({ cards }), 1500);
        if (cards.length === 0) {
            alert("Hai cancellato tutto!!")
        }
    }

    handleDeleteBook = bookId => {
        const books = this.state.books.filter(book => book.id !== bookId);
        alert(`Hai eliminato "${{ ...this.state.books.filter(book => book.id === bookId)[0] }.titolo}"!`);
        setTimeout(() => this.setState({ books }), 1500);
        if (books.length === 0) {
            alert("Hai cancellato tutto!!")
        }
    }

    handleIncrement = card => {
        const cards = [...this.state.cards];
        const id = cards.indexOf(card);
        card[id] = { ...card };
        this.setState({ cards });
        (cards[id].quantità >= cards[id].quantitàmassima) ?
            alert(`Hai raggiunto il numero massimo per "${cards[id].nome}"!`) :
            cards[id].quantità++;
    }

    handleDecrement = card => {
        const cards = [...this.state.cards];
        const id = cards.indexOf(card);
        card[id] = { ...card };
        this.setState({ cards });
        if (cards[id].quantità > 0) {
            cards[id].quantità--
        }
    }

    handleTotal = () => {
        let sum = 0;
        for (let i = 0; i < this.state.cards.length; i++) {
            sum += this.state.cards[i].quantità;
        }
        return sum
    }

    handleTotalPrice = () => {
        let sum = 0;
        for (let i = 0; i < this.state.cards.length; i++) {
            sum += this.state.cards[i].prezzo * this.state.cards[i].quantità;
        }
        return sum.toFixed(3)
    }

    handleReset = card => {
        const cards = [...this.state.cards];
        const id = cards.indexOf(card);
        card[id] = { ...card };
        this.setState({ cards });
        if (cards[id].quantità !== 0) {
            cards[id].quantità = 0
        }
    }

    handleTotalReset = () => {
        const cards = [...this.state.cards];
        this.setState({ cards });
        for (let i = 0; i < this.state.cards.length; i++) {
            cards[i].quantità = 0;
        }
    }

    handleMax = card => {
        const cards = [...this.state.cards];
        const id = cards.indexOf(card);
        card[id] = { ...card };
        this.setState({ cards });
        if (cards[id].quantità !== cards[id].quantitàmassima) {
            cards[id].quantità = cards[id].quantitàmassima
        }
    }

    handleTotalMax = () => {
        const cards = [...this.state.cards];
        this.setState({ cards });
        for (let i = 0; i < this.state.cards.length; i++) {
            cards[i].quantità = this.state.cards[i].quantitàmassima;
        }
    }

    handleOrder = card => {
        const cards = [...this.state.cards];
        const id = cards.indexOf(card);
        card[id] = { ...card };
        this.setState({ cards });
        document.getElementById("orderbar").textContent = `✅ Hai ordinato "${cards[id].nome} Roll"! ✅`
    }

    render() {
        return (
            <ThemeProvider
                breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
                minBreakpoint="xxs"
            >
                <Router>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Mostafa Milani Amin</title>
                        <link rel="canonical" href="https://mostafamilani.ir/" />
                    </Helmet>
                    <MyNavbar />
                    {/* <Sidebar /> */}
                    <Container fluid style={{ margin: "10px -10px 10px 0px" }}>
                    <MySplitButton />
                        <Routes>
                            <Route path="/demo/sushi" element={
                                <>
                                    <Infobar
                                        onTotal={this.handleTotal}
                                        onTotalPrice={this.handleTotalPrice}
                                        onTotalReset={this.handleTotalReset}
                                        onTotalMax={this.handleTotalMax}
                                    />
                                    <Row xs={1} md={3} className="g-4">
                                        {this.state.cards.map(card => (
                                            <Sushi
                                                key={card.id}
                                                onDelete={this.handleDelete}
                                                onIncrement={this.handleIncrement}
                                                onDecrement={this.handleDecrement}
                                                onReset={this.handleReset}
                                                onMax={this.handleMax}
                                                onOrder={this.handleOrder}
                                                card={card}
                                            />
                                        ))}
                                    </Row>
                                </>
                            } />
                            <Route path="/islam/fonti-islamiche" element={
                                <Row xs={1} md={3} className="g-4">
                                    {this.state.books.map(book => (
                                        <FontiIslamiche
                                            key={book.id}
                                            onDeleteBook={this.handleDeleteBook}
                                            book={book} />
                                    ))}
                                </Row>
                            } />
                            <Route path="/islam/islam-per-tutti" element={<IslamPerTutti />} />
                            <Route path="/articoli" element={<Articoli />} />
                            <Route path="/articoli/articoli-islamici" element={<ArticoliIslamici />} />
                            <Route path="/" element={<Home />} />
                            <Route path="/principali/biografia" element={<Biografia />} />
                            <Route path="/milani" element={<Biografia />} />
                            <Route path="/principali/contattami" element={<AppMail />} />
                            <Route path="/farsi" element={<Farsi />} />
                            <Route path="/demo/cucina" element={


                                <Row xs={1} md={3} className="bg text-white">
                                    {this.state.recipes.map(recipe => (
                                        <Cucina
                                            key={recipe.id}
                                            onDeleteBook={this.handleDeleteBook}
                                            recipe={recipe} />
                                    ))}
                                </Row>} />
                            <Route path="*" element={<PageNotFound />} />
                            <Route path="/functions/most-repeated-letter" element={<MostRepeatedLetter />} />
                            <Route path="/functions/caesar-cipher" element={<CaesarCipher />} />
                            <Route path="/functions/spell-integer" element={<SpellInteger />} />
                            <Route path="/functions/remove-emoji" element={<RemoveEmoji />} />
                            <Route path="/functions/remove-accent" element={<RemoveAccent />} />
                            <Route path="/functions/celsius-fahrenheit-kelvin" element={<CelsiusFahrenheitKelvin />} />
                            <Route path="/functions/test-palindrome" element={<TestPalindrome />} />
                            <Route path="/functions/randopass" element={<RandoPass />} />
                            <Route path="/functions/abjad" element={<Abjad />} />
                            <Route path="/functions/fibonacci" element={<Fibonacci />} />
                            <Route path="/functions/arabic-to-roman" element={<ArabicToRoman />} />
                            <Route path="/functions/all-functions" element={<AllFunctions />} />
                            <Route path="/functions/http-req" element={
                                <>
                                    <HttpReq />
                                    <Tabs
                                        defaultActiveKey="get"
                                        id="uncontrolled-tab-example"
                                        className="mb-3"
                                    >
                                        <Tab eventKey="get" title="GET">
                                            <GetReq />
                                            <Container />
                                        </Tab>
                                        <Tab eventKey="post" title="POST">
                                            <PostReq />
                                            <Container />
                                        </Tab>
                                        <Tab eventKey="delete" title="DELETE">
                                            <DeleteReq />
                                            <Container />
                                        </Tab>
                                        <Tab eventKey="import" title="IMPORT">
                                            <ImportUrl />
                                            <Container />
                                        </Tab>
                                    </Tabs>
                                </>

                            } />

                            <Route path="/socials" element={<Socials />} />
                            <Route path="/prova" element={<Prova />} />



                            <Route
                                path='/home-chat'
                                element={
                                    <HomeChat
                                    // username={this.chatState.username}
                                    // room={this.chatState.room}
                                    // socket={this.socket}
                                    />
                                }
                            />
                            <Route
                                path='/home-chat/chat'
                                element={<Chat
                                    // username={this.chatState.username}
                                    // room={this.chatState.room}
                                    // socket={this.socket}
                                    />
                                }
                            />




                        </Routes>
                    </Container>
                </Router>
            </ThemeProvider>
        )
    }
}

export default App;