import React from 'react';
import sidebar from './sidebar.css'
import { Col, Row, Container } from 'react-bootstrap';

class Sidebar extends React.Component {
    render() {
        return (
            <nav class="main-menu">
                <ul>
                    <li>
                        <a href="/">
                            <i className="fa fa-home fa-2x"></i>
                            <span className="nav-text">
                                Home
                            </span>
                        </a>

                    </li>
                    <li className="has-subnav">
                        <a href="/islam/islam-per-tutti">
                            <i className="fa fa-laptop fa-2x"></i>
                            <span className="nav-text">
                                Islam per Tutti
                            </span>
                        </a>

                    </li>
                    <li className="has-subnav">
                        <a href="/islam/fonti-islamiche">
                            <i className="fa fa-book fa-2x"></i>
                            <span className="nav-text">
                                Fonti Islamiche
                            </span>
                        </a>

                    </li>
                    <li className="has-subnav">
                        <a href="/articoli/indice-articoli">
                            <i className="fa fa-pencil fa-2x"></i>
                            <span className="nav-text">
                                Articoli
                            </span>
                        </a>

                    </li>
                    <li className="has-subnav">
                        <a href="/functions/http-req">
                            <i className="fa fa-tasks fa-2x"></i>
                            <span className="nav-text">
                                Richieste Http (Axios)
                            </span>
                        </a>

                    </li>
                    <li>
                        <a href="/functions/all-functions">
                            <i className="fa fa-bar-chart-o fa-2x"></i>
                            <span className="nav-text">
                                All Functions
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="/farsi">
                            <i className="fa fa-font fa-2x"></i>
                            <span className="nav-text">
                                فارسی
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="/demo/sushi">
                            <i className="fa fa-align-justify fa-2x"></i>
                            <span className="nav-text">
                                Sushi (Demo)
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="/demo/cucina">
                            <i className="fa fa-cutlery fa-2x"></i>
                            <span className="nav-text">
                                Cucina
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="/principali/contattami">
                            <i className="fa fa-envelope-o fa-2x"></i>
                            <span className="nav-text">
                                Contattami
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="/home-chat">
                            <i className="fa fa-comments fa-2x"></i>
                            <span className="nav-text">
                                Chat
                            </span>
                        </a>
                    </li>
                </ul>
                {/* <ul className="logout">
                <li>
                   <a href="#">
                         <i className="fa fa-power-off fa-2x"></i>
                        <span className="nav-text">
                            Logout
                        </span>
                    </a>
                </li>  
            </ul> */}
            </nav>








        )
    }

}

export default Sidebar;