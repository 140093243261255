import React from 'react';
import axios from 'axios';
import { Alert, Container } from 'react-bootstrap'
import copy from 'copy-to-clipboard'
import print from 'ink-html'

export default class DeleteReq extends React.Component {
  state = {
    id: '',
    output: ""
  }

  handleChange = event => {
    this.setState({ id: event.target.value });
  }

  handleSubmit = event => {
    event.preventDefault();


    axios.delete(`https://jsonplaceholder.typicode.com/posts/${this.state.id}`)
      .then(res => {
        console.log(res);
        console.log(res.data);
        this.setState({ output: JSON.stringify(res) })
      })
  }

  render() {
    return (

      <Container fluid>
        <h4>Implementare una richiesta DELETE</h4>
        <h6>Per cancellare un oggetto da una API, potrai ricorrere al metodo axios.delete passando, come parametro della richiesta, l'URL dell'oggetto da cancellare.
          Nell'esempio, puoi notare lo stesso codice implementato per il form con il metodo POST ma in cui ti è permesso di cancellare le informazioni di un messaggio creato in precedenza inserendone il relativo ID.</h6>

        <form onSubmit={this.handleSubmit}>

          <div className="input-group mb-3">
            <button type="submit" className="btn btn-success" id="button-addon1"><h5>Mostra la risposta!</h5></button>

            <input type="text" name="title" onChange={this.handleChange} className="form-control" placeholder="Inserisci il numero dell'ID dell'oggetto che vuoi cancellare."/>

            <button onClick={() => this.setState({ output: `` })} className="btn btn-danger" type="button" id="button-addon1"><h5>Reset! 🗑️</h5></button>
          </div>


          <Alert className="fnoutput" id="deletereq" key="info" variant="info" show="false">{this.state.output}</Alert>

          <button onClick={() => copy(`${this.state.output}`)} className="btn btn-primary" type="button" id="button-addon1">Copy the result in the clipboard 📋</button>
          <button onClick={() => print(document.querySelector('#deletereq'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button>
          <button onClick={() => window.location.reload(false)} className="btn btn-warning" type="button" id="button-addon1" style={{ align: "left" }}>Reload! 🔄</button>

        </form>

      </Container>
    )
  }
}



