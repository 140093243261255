import React from 'react';
import axios from 'axios';
import { Alert, Container } from 'react-bootstrap'
import copy from 'copy-to-clipboard'
import print from 'ink-html'

export default class PostReq extends React.Component {
  state = {
    title: '',
    output: ""
  }

  handleChange = event => {
    this.setState({ name: event.target.value });
  }

  handleSubmit = event => {
    event.preventDefault();

    const post = {
      title: this.state.name
    };

    axios.post(`https://jsonplaceholder.typicode.com/posts`, { post })
      .then(res => {
        // console.log(res);
        // console.log(res.data);
        this.setState({ output: JSON.stringify(res.data) })
      })

      

  }

  render() {
    return (

<Container fluid>
<h4>Implementare una richiesta POST</h4>
        <h6>All'interno della funzione handleSubmit di questo programma, è stata stabilita la action di default del form e viene aggiornato lo stato con il titolo del messaggio.
        Tramite il metodo POST, ti verrà restituito lo stesso oggetto di risposta con le informazioni utilizzabili in una chiamata then.
        Raccolto l'input del post, il codice lo aggiunge alla richiesta di POST, che darà una risposta. La risposta sarà visibile qui sotto.</h6>
        
        <form onSubmit={this.handleSubmit}>

        <div className="input-group mb-3">
        <button type="submit" className="btn btn-success" id="button-addon1"><h5>Mostra la risposta!</h5></button>

            <input type="text" name="title" onChange={this.handleChange} className="form-control"  placeholder="Inserisci la stringa che vuoi che venga mandata come valore della proprietà title della richiesta POST."/>

          <button onClick={() => this.setState({ output: `` })} className="btn btn-danger" type="button" id="button-addon1"><h5>Reset! 🗑️</h5></button>
          </div>

            
            <Alert className="fnoutput" id="postreq" key="info" variant="info" show="false">{this.state.output}</Alert>

            <button onClick={() => copy(`${this.state.output}`)} className="btn btn-primary" type="button" id="button-addon1">Copy the result in the clipboard 📋</button>
            <button onClick={() => print(document.querySelector('#postreq'))} className="btn btn-info" type="button" id="button-addon1">Print 🖨️</button>
            <button onClick={() => window.location.reload(false)} className="btn btn-warning" type="button" id="button-addon1" style={{ align: "left" }}>Reload! 🔄</button>

        </form>

      </Container>
    )
  }
}


              
              
