import React from "react";
import { createButton, createSvgIcon, FacebookLoginButton, GoogleLoginButton, GithubLoginButton, TwitterLoginButton, AmazonLoginButton, InstagramLoginButton, LinkedInLoginButton, MicrosoftLoginButton, BufferLoginButton, TelegramLoginButton, AppleLoginButton, DiscordLoginButton, SlackLoginButton, OktaLoginButton, YahooLoginButton } from "react-social-login-buttons";
import { Container, Col, Row } from 'react-bootstrap';
import p from '../multimedia/images/p.svg'

class Socials extends React.Component {
    render() {
        return (
            <Container fluid>

                <Row>
                    <Col>
                        <GoogleLoginButton onClick={() => alert("Hello")} />
                        <GithubLoginButton onClick={() => alert("Hello")} />
                    </Col>
                    <Col>
                        <TwitterLoginButton onClick={() => alert("Hello")} />
                        <AmazonLoginButton onClick={() => alert("Hello")} />
                    </Col>
                    <Col>
                        <InstagramLoginButton onClick={() => alert("Hello")} />
                        <LinkedInLoginButton onClick={() => alert("Hello")} />
                    </Col>
                    <Col>
                        <MicrosoftLoginButton onClick={() => alert("Hello")} />
                        <BufferLoginButton onClick={() => alert("Hello")} />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <TelegramLoginButton onClick={() => alert("Hello")} />
                        <AppleLoginButton onClick={() => alert("Hello")} />
                    </Col>
                    <Col>
                        <DiscordLoginButton onClick={() => alert("Hello")} />
                        <SlackLoginButton onClick={() => alert('Hello')} />
                    </Col>
                    <Col>
                        <OktaLoginButton onClick={() => alert('Hello')} />
                        <YahooLoginButton onClick={() => alert('Hello')} />
                    </Col>
                    <Col>
                        <FacebookLoginButton onClick={() => alert("Hello")} />
                        <MyFacebookLoginButtonPersonal onClick={() => alert("Hello")} />
                    </Col>
                </Row>

                <Row>

                </Row>

            </Container>
        )
    }
}

export default Socials;

const config = {
    text: "Log in with Facebook",
    icon: "facebook",
    iconFormat: name => `fa fa-${name}`,
    style: { background: "#3b5998" },
    activeStyle: { background: "#293e69" }
  };
  /** My Facebook login button. */

  const configSvg = {
    text: "Log in with Facebook",
    icon: createSvgIcon(/* svgIcon */),
    iconFormat: name => `fa fa-${name}`,
    style: { background: "#3b5998" },
    activeStyle: { background: "#293e69" }
  };
  /** My Facebook login button with svg icon. */

  const configPersonal = {
    text: "Log in with Personal",
    icon: createSvgIcon(p),
    iconFormat: name => `fa fa-${name}`,
    style: { background: "#9b5998" },
    activeStyle: { background: "#293e69" }
  };
  /** My Personal login button. */


  const MyFacebookLoginButton = createButton(config);
  const MyFacebookLoginButtonSvg = createButton(configSvg);
  const MyFacebookLoginButtonPersonal = createButton(configPersonal);



