import React from 'react'

import { Col, Carousel, CloseButton, Card, Container, Button } from 'react-bootstrap'
// import ImageGallery from 'react-image-gallery';
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player'
import Moment from 'react-moment'
import { Row } from 'react-bootstrap'
import StickyBox from 'react-sticky-box'

// import { A11y, Autoplay, Controller, EffectCards, EffectCoverflow, EffectCreative, EffectCube, EffectFade, EffectFlip, FreeMode, Grid, HashNavigation, History, Keyboard, Lazy, Manipulation, Mousewheel, Navigation, Pagination, Parallax, Scrollbar, Thumbs, Virtual, Zoom } from 'swiper';
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay'
import swiper from '../components/my-swiper/my-swiper.js'

// import haha from '../multimedia/videos/haha.mp4'
import jsredbg from '../multimedia/images/jsredbg.png'
import jsbluebg from '../multimedia/images/jsbluebg.png'
import jsgreenbg from '../multimedia/images/jsgreenbg.png'
import milani from '../multimedia/images/milani.jpg'
import sitonuovo from '../multimedia/images/sito-nuovo.jpg'



// const swiper = new Swiper('.swiper', {
//   // Install modules
//   modules: [Autoplay],

//   // ...
// });

export class Home extends React.Component {

  // images = [
  //   {

  //     original: jsredbg,
  //     originalTitle: "",
  //     originalAlt: "",
  //     originalHeight: "425px",
  //     originalWidth: "100vw",
  //     originalClass: "",
  //     renderItem: "", /* Function for custom rendering a specific slide (see renderItem below) */

  //     thumbnail: jsredbg,
  //     thumbnailTitle: "",
  //     thumbnailAlt: "",
  //     thumbnailLoading: "", /*  image loading. Either "lazy" or "eager" (html5 attribute) */
  //     thumbnailClass: "",
  //     renderThumbInner: "", /* Function for custom thumbnail renderer (see renderThumbInner below) */

  //     description: "RED SLIDE",
  //     srcSet: "",
  //     sizes: "", /* image sizes (html5 attribute) */
  //     loading: "lazy", /*  image loading. Either "lazy" or "eager" (html5 attribute) */

  //     bulletClass: "", /* extra class for the bullet of the item */
  //     bulletOnClick: "" /* - callback({item, itemIndex, currentIndex}) */

  //     /* ... for more: https://www.npmjs.com/package/react-image-gallery */

  //   },
  //   {
  //     original: jsbluebg,
  //     originalHeight: "425px",

  //     thumbnail: jsbluebg,

  //     description: "BLU SLIDE",
  //   },
  //   {
  //     original: jsgreenbg,
  //     originalHeight: "425px",

  //     thumbnail: jsgreenbg,

  //     description: "GREEN SLIDE",
  //   },
  // ];

  //   swiper = new Swiper('.swiper', {
  //   // Install modules
  //   modules: [Autoplay]

  // });


  render() {




    return (


      <Swiper
        Aautoplay
        spaceBetween={-732.5}
        slidesPerView={2}
        onAutoplay={() => console.log('autoplay')}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        style={{ background: "#000435" }}
      >



        <SwiperSlide>
          <Col>
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row><br />
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row>
          </Col>
        </SwiperSlide>

        <SwiperSlide>
          <Col>
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row><br />
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row>
          </Col>
        </SwiperSlide>

        <SwiperSlide>
          <Col>
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row><br />
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row>
          </Col>
        </SwiperSlide>

        <SwiperSlide>
          <Col>
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row><br />
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row>
          </Col>
        </SwiperSlide>

        <SwiperSlide>
          <Col>
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row><br />
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row>
          </Col>
        </SwiperSlide>

        <SwiperSlide>
          <Col>
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row><br />
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row>
          </Col>
        </SwiperSlide>

        <SwiperSlide>
          <Col>
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row><br />
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row>
          </Col>
        </SwiperSlide>

        <SwiperSlide>
          <Col>
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row><br />
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row>
          </Col>
        </SwiperSlide>

        <SwiperSlide>
          <Col>
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row><br />
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row>
          </Col>
        </SwiperSlide>

        <SwiperSlide>
          <Col>
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row><br />
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row>
          </Col>
        </SwiperSlide>

        <SwiperSlide>
          <Col>
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  alt="First slide"
                />
              </a>
            </Row><br />
            <Row>
              <a href="https://mostafamilani.ir/">
                <img id="swiper-slide-image" className="d-block w-25"
                  src={sitonuovo}
                  href="https://mostafamilani.ir/"
                  alt="First slide"
                />
              </a>
            </Row>
          </Col>
        </SwiperSlide>






        {/* https://swiperjs.com/react */}
      <br/><h1 style={{textAlign: "center"}}>👉<a style={{color:"gold", textDecoration: "none"}} href="https://mostafamilani.ir/"><span>VAI AL NUOVO SITO!</span></a>👈</h1>

      </Swiper>


      //           <ImageGallery
      //             items={this.images}
      //             autoPlay
      //             showIndex="false"
      //             showBullets="false"
      //             isRTL="false  " /* gallery's direction will be from right-to-left (to support right-to-left languages) */
      //             thumbnailPosition="bottom"
      //             lazyLoad="true"
      //             disableSwipe="true"
      //             showNav="true"
      //             showThumbnails="false"
      //             showFullscreenButton="true"
      //             useBrowserFullscreen="true"
      //             useTranslate3D="true"
      //             showPlayButton="false"

      // />



      /* npm uninstall react-image-gallery */


      /* <Carousel fluid fade="true">
            <Carousel.Item>
              <img id="swiper-slide-image"
                className="d-block w-50"
                src={jsredbg}
                alt="First slide"
              />
              <Carousel.Caption>
                <h5>👇 DATA ED ORA ESATTA 👇</h5>
                <Moment id="moment" format="HH:mm:ss (DD/MM/YYYY)" interval={1} />
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img id="swiper-slide-image"
                className="d-block w-50"
                src={jsbluebg}
                alt="Second slide"
              />
              <Carousel.Caption>
                <h5>👇 DATA ED ORA ESATTA 👇</h5>
                <Moment id="moment" format="HH:mm:ss (DD/MM/YYYY)" interval={1} />
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-50"
                src={jsgreenbg}
                alt="Third slide"
              />
              <Carousel.Caption>
                <h5>Third slide label</h5>
                <h5>👇 DATA ED ORA ESATTA 👇</h5>
                <Moment id="moment" format="HH:mm:ss (DD/MM/YYYY)" interval={1} />
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel> */








    )
  }
}

export class Biografia extends React.Component {
  render() {
    return (
      <Container fluid>
        <div className="clearfix" style={{ textAlign: "justify" }}>
          <img src={milani} style={{ width: "380px", heigth: "380px" }} class="col-md-6 float-md-end mb-3 ms-md-3" alt="..." />
          <h1>Mostafa Milani Amin</h1>
          <p>Mostafa Milani Amin (Teheran, 28 settembre 1970) è un chierico sciita iraniano, studioso, ricercatore, esperto in scienze islamiche e coraniche, e traduttore di diverse opere islamiche; nella gerarchia dei dotti sciiti, è un "hujjat al-Islam".</p>
          <p>Nasce in una famiglia musulmana sciita originaria della regione iraniana dell’Azerbaijan Orientale, nella provincia di Tabriz. All’età di due anni si trasferisce in Italia, a Milano, dove frequenta le scuole dell’obbligo, l’Istituto tecnico industriale statale ITIS, e infine la facoltà di Ingegneria Elettronica al Politecnico di Milano, ma durante il quarto anno di università, nel 1994, dopo circa sedici anni dalla Rivoluzione Islamica dell’Iran, matura la decisione di tornare nel suo paese di origine, a Qom, a studiare scienze islamiche al seminario islamico della medesima città. Si dedica agli studi delle scienze islamiche come studente dei migliori docenti del mondo religioso sciita, come gli ayatollah Makarem Shirazi, Hossein Vahid Khorasani, Seyyed Mahmoud Hashemi Shahroudi, Seyyed Mousa Shubairi Zanjani, Seyyed Mohammad Mahdi Mirbagheri, Abol Ghassem Alidoust, e anche uno dei più importanti che è stato, dal 2009 al 2019, capo della magistratura della Repubblica Islamica dell'Iran, l’ayatollah Sadeq Amoli Larijani, come docente dei Principi del Fiqh (giurisprudenza islamica).</p>
          <p><h4>Traduzioni in lingua italiana</h4>
            Da anni si occupa di traduzioni di testi islamici in lingua italiana, molti dei quali presenti nelle principali biblioteche italiane e disponibili anche online:<br />
            <ul>
              <li>
                <a style={{ color: "gold" }} href="https://it.islamic-sources.com/Books/alluhuf" target="_blank" rel="noreferrer" title="Narrazione della tragica vicenda di Karbalà e del martirio dell'Imam Hussain.">La Retta Via (500 tradizioni del sommo Profeta e della sua immacolata Famiglia).</a>
              </li>
              <li>
                <a style={{ color: "gold" }} href="https://it.islamic-sources.com/Books/la-parola-di-ali-le-sentenze-brevi-del-nahju-l-balagah" target="_blank" rel="noreferrer">La Parola di Alì (le sentenze brevi del Nahju-l-balāġaħ).</a>
              </li>
              <li>
                <a style={{ color: "gold" }} href="https://it.islamic-sources.com/Books/la-supplica-del-pianto-dua-nudbah" target="_blank" rel="noreferrer">La Supplica del Pianto (Du’a Nudbah).</a>
              </li>
              <li>
                <a style={{ color: "gold" }} href="https://it.islamic-sources.com/Books/compendio-della-dottrina-islamica-allamah-tabatabai" target="_blank" rel="noreferrer">Compendio della Dottrina Islamica (Allamah Tabatabai).</a>
              </li>
              <li>
                <a style={{ color: "gold" }} href="https://it.islamic-sources.com/Books/alluhuf" target="_blank" rel="noreferrer" title="Narrazione della tragica vicenda di Karbalà e del martirio dell'Imam Hussain.">Alluhuf (narrazione delle vicende della tragedia di Karbala).</a>
              </li>
              <li>
                La Luce del Corano (tomi I, II, III, IV) - Esegesi del sacro Corano.
              </li>
              <li>
                Una Lacrima per Husayn.
              </li>
              <li>
                I Quattordici Infallibili- Dati Biografici.
              </li>
              <li>
                Il Messaggio di Gadir (tradizione di Gadir).
              </li>
              <li>
                Conoscere il Nahju-l-balagah.
              </li>
              <li>
                La purificazione dell’anima e l’acquisizione delle virtù.
              </li>
            </ul>
          </p>
          <p>Ha collaborato col sito <a style={{ color: "gold" }} href="http://www.islamic-sources.com" target="_blank" rel="noreferrer">Islamic-sources.com</a>, con decine di libri, centinaia di articoli, discussioni, traduzioni e altro materiale sull’Islam. Ha una pagina in <a style={{ color: "gold" }} href="https://www.facebook.com/Mostafa.Milani.Amin.Fb/" target="_blank" rel="noreferrer">Facebook</a> in cui tratta tematiche regiose, sociali, politiche e culturali.</p>
          <p>Ha collaborato con diverse organizzazioni e fondazioni iraniane come World Assembly Ahlulbayt, Università Al Mostafa, ecc.</p>
          <p><h4>Alcune interviste e articoli</h4>
            <ul>
              <li>
                <a style={{ color: "gold" }} href="http://www.pinonicotri.it/2009/06/cena-triste-sulliran/" target="_blank" rel="noreferrer">Arruota libera 15/06/2009 - Cena triste sull’Iran.</a>
              </li>
              <li>
                <a style={{ color: "gold" }} href="http://www.ilsole24ore.com/art/notizie/2013-06-13/liran-volta-pagina-pesante-195212.shtml?uuid=Abk3Rn4H&amp;refresh_ce=1" target="_blank" rel="noreferrer">Il Sole 24 Ore 13/06/2013 - L’Iran volta pagina dopo la pesante eredità di Ahmadinejad.</a>
              </li>
              <li>
                <a style={{ color: "gold" }} href="http://www.ilgiornale.it/news/milano/mustafa-ingegnere-milano-diventato-ayatollah-iran-942935.html" target="_blank" rel="noreferrer">Il Giornale 13/08/2013 - Mustafa, ingegnere a Milano diventato «ayatollah» in Iran.</a>
              </li>
              <li>
                <a style={{ color: "gold" }} href="https://www.lettera43.it/isis-come-funziona-il-sistema-giuridico/" target="_blank" rel="noreferrer">Lettera43 22/04/2015 - Isis, come funziona il sistema giuridico.</a>
              </li>
              <li>
                <a style={{ color: "gold" }} href="http://www.rai.tv/dl/RaiTV/programmi/media/ContentItem-e3f03aff-ba11-45e8-a5c9-fdcf1118b7d8.html" target="_blank" rel="noreferrer">Speciale Tg1 28/06/2015 - L’Iran a Colori.</a>
              </li>
              <li>
                <a style={{ color: "gold" }} href="http://www.repubblica.it/esteri/2015/07/11/news/mostafa_milani-118869147/" target="_blank" rel="noreferrer">La Repubblica 11/07/2015 - Mostafa Milani: “L’Iran vuole un’intesa politica ma l’Occidente non sia arrogante”.</a>
              </li>
              <li>
                <a style={{ color: "gold" }} href="http://archiviostorico.corriere.it/2015/luglio/23/Mostafa_sciita_che_caccia_jihadisti_co_0_20150723_dadabf64-30fa-11e5-ab1c-0496abde2976.shtml?refresh_ce-cp" target="_blank" rel="noreferrer">Corriere della Sera 23/07/2015 - “Mostafa, lo sciita che dà la caccia ai jihadisti”.</a>
              </li>
              <li>
                <a style={{ color: "gold" }} href="https://www.radioradicale.it/scheda/453477/spazio-transnazionale" target="_blank" rel="noreferrer">Radio Radicale 18/09/15 Spazio Transnazionale di Francesco De Leo (dall'istante "22:20" del file audio).</a>
              </li>
              <li>
                <a style={{ color: "gold" }} href="http://italian.irib.ir/analisi/interviste/item/200410-mustafa-milani-amin-all%E2%80%99irib-%E2%80%9D-le-vittime-di-strage-di-la-mecca-maggiormente-iraniane-e-africane-per-le-politiche-discriminatorie-dei-sauditi,-l%E2%80%99hajj-va-gestito-dai-musulmani%E2%80%9D-audio" target="_blank" rel="noreferrer">Radio IRIB 28/09/15 - Mostafa Milani Amin all’IRIB sulla strage di Minā: "L’Hajj va gestito dai musulmani e non dai sauditi".</a>
              </li>
              <li>
                <a style={{ color: "gold" }} href="https://parstoday.com/it/news/world-i41539-mostafa_milani_arabia_saudita_si_ostina_a_minimizzare_la_tragedia_della_mecca_(audio)" target="_blank" rel="noreferrer">Mostafa Milani: "Arabia Saudita si ostina a minimizzare la tragedia della Mecca".</a>
              </li>
              <li>
                <a style={{ color: "gold" }} href="https://parstoday.com/it/news/world-i41554-mostafa_milani_il_wahhabismo_e'_la_dottrina_dell'odio_(audio)" target="_blank" rel="noreferrer">Mostafa Milani: "il wahhabismo è la dottrina dell'odio".</a>
              </li>
            </ul>
          </p>
          <p><h4>Curiosità</h4>
            Vive attualmente a Qom in Iran con la sua famiglia.<br />
            Lingue conosciute: persiano, italiano, arabo.<br />
            Ha portato quasi a termine la sua traduzione in lingua italiana del sacro Corano, che a differenza di molte traduzioni attualmente esistenti, è stata integralmente tradotta direttamente dal sacro testo originale arabo.
          </p>
        </div>
        {/* <ReactPlayer url={haha} controls={true} mute="true" loop={true} playing={true} /> */}
      </Container>
    )
  }
}

export class Farsi extends React.Component {
  render() {
    return (
      <Container fluid style={{ direction: "RTL", textAlign: "right" }}>
        <h1>فارسی</h1>
        <h4>مصطفی میلانی امین</h4>


        <div className="row">
          <StickyBox offsetTop={20} offsetBottom={20}>
            <div>Sidebar</div>
          </StickyBox>
          <div>Content</div>
        </div>

      </Container>
    )
  }
}

export class Sushi extends React.Component {
  render() {
    return (
      <Container fluid>
        <Col>
          <div id="card" className="card">
            <button onClick={() => this.props.onIncrement(this.props.card)} className="btn btn-primary">
              Aggiungi <span className="badge text-bg" id="badge">{this.props.card.quantità}</span>
              <span className="badge text-bg" id="badge">🏷️{(this.props.card.prezzo * this.props.card.quantità).toFixed(3)}€🏷️</span>
            </button>
            <button onClick={() => this.props.onDecrement(this.props.card)} className="badge text-bg" id="badge">
              Diminuisci</button>
            <button onClick={() => this.props.onReset(this.props.card)} className="badge text-bg" id="badge">
              Azzera</button>
            <button onClick={() => this.props.onMax(this.props.card)} className="badge text-bg" id="badge">
              Massimo</button>
            <img src={this.props.card.immagine} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">{this.props.card.nome} Roll 🏷️{this.props.card.prezzo}€</h5>
              <button onClick={() => this.props.onOrder(this.props.card)} className="btn btn-outline-primary">Ordina ✅</button>
              <Link className="btn btn-outline-success" to={this.props.card.immagine} target="_blank" download>Download ⬇️</Link>
              <button className="btn btn-danger" onClick={() => this.props.onDelete(this.props.card.id)}>Elimina ❌</button>
            </div>
          </div>
        </Col>
      </Container>
    )
  }
}

export class FontiIslamiche extends React.Component {
  render() {
    return (
      <Container fluid>
        <Col>
          <CloseButton variant="white" onClick={() => this.props.onDeleteBook(this.props.book.id)} />
          <Card>
            <Card.Img variant="top" src={this.props.book.immagine} alt="..." />
            <Card.Body>
              <Card.Title>{this.props.book.titolo}</Card.Title>
              <Card.Text style={{ overflowY: "auto", maxHeight: "48px" }}>
                {this.props.book.descrizione}
              </Card.Text>
              <a className="btn btn-outline-danger" href={this.props.book.dlpdf} onClick={() => this.props.book.hasOwnProperty("dlpdf") ? this.props.book.dlpdf : alert(`Questo formato non è disponibile per "${this.props.book.titolo}"!`)}>PDF</a>
              <a className="btn btn-outline-primary" href={this.props.book.dldocx} onClick={() => Object.hasOwn(this.props.book, "dldocx") ? this.props.book.dldocx : alert(`Questo formato non è disponibile per "${this.props.book.titolo}"!`)}>📝DOCX</a>
              <a className="btn btn-outline-success" target="_blank" rel="noreferrer" href={this.props.book.info}>📚 Maggiori informazioni</a>
            </Card.Body>
          </Card >
        </Col >
      </Container>
    )
  }
}

export class IslamPerTutti extends React.Component {
  render() {
    return (
      <Container fluid>
        <h1>ISLAM PER TUTTI</h1>
        <h4>Principi e precetti dell'Islam spiegati in modo semplice...</h4>
        <ReactPlayer url="https://youtube.com/playlist?list=PLwR4si3qqrrBAlyo0VZwF3QH1IFEwUbVE" controls={true} mute="false" loop={false} playing={false} />
      </Container>
    )
  }
}

export class PageNotFound extends React.Component {
  render() {
    return (
      <Container fluid>
        <Col>
          <div>
            <h1>Page Not Found</h1>
            <h4>We couldn't find what you were looking for.</h4>
            <p>Please contact the owner of the site that linked you to the original URL and let them know their link is broken.</p>
          </div>
        </Col>
      </Container>
    )
  }
}